import {Application as Stimulus} from 'stimulus'
import {definitionsFromContext} from 'stimulus/webpack-helpers'
import TocController from 'static/controllers/toc-controller'

let stimulus

export default function () {
  stimulus = Stimulus.start()
  stimulus.load(definitionsFromContext(require.context('static/controllers', true, /.js$/)))
  stimulus.load(definitionsFromContext(require.context('static/controllers/services', true, /.js$/)))
  stimulus.load(definitionsFromContext(require.context('static/controllers/instant-quotes', true, /.js$/)))
  stimulus.load(definitionsFromContext(require.context('../../../components/websites/tfe/components', true, /.js$/)))
  stimulus.register('toc', TocController)
}
