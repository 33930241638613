import {Controller} from 'stimulus'
import SquareProcessor from 'tfx-client/components/bills/square_processor'
import StripeProcessor from 'tfx-client/components/bills/stripe_processor'
import TestProcessor from 'tfx-client/components/bills/test-processor'
import cable from 'tfx-admin/cable'
import {Turbo} from '@hotwired/turbo-rails'
import * as Sentry from '@sentry/vue'
import {Notifier} from 'tfx-common/components/notifier'

export default class extends Controller {
  static targets = ['loading', 'errorMsg', 'modal', 'overlay', 'pay']
  static values = {domBillId: String, billId: Number, embill: String, userId: String, processorName: String, url: String}

  connect() {
    this.notifier = new Notifier()
    this.init()
    if (!this.processorNameValue) return
    if (this.embillValue === '') this.embillValue = null
    this.subscribeToChannel()
    this.initPaymentProcessor()
  }

  disconnect() {
    if (this.channel) this.channel.unsubscribe()
  }

  init() {
    this.hideProcessing()
    this.showPaymentCardArea = true
    this.paymentProcessor = null
  }

  open() {
    document.body.classList.add('overflow-hidden')
    this.modalTarget.classList.remove('modal-container-hide')
    document.querySelector('.global-overlay').classList.add('global-overlay-show')
    this.element.classList.add('modal-displayed', 'modal-show-window')
    Turbo.cache.clear()

    if (this.paymentProcessor) {
      this.paymentProcessor = null
    }

    const paymentContainer = document.getElementById('payment-form')
    if (paymentContainer) {
      paymentContainer.innerHTML = '<div id="payment-form"></div>'
    }
    this.subscribeToChannel()
    this.initPaymentProcessor()
  }

  close() {
    if (this.processorNameValue === 'square' || this.processorNameValue === 'square_2nd_processor') {
      window.location.reload()
    } else {
      document.body.classList.remove('overflow-hidden')
      document.querySelector('.modal-container').classList.add('modal-container-hide')
      document.querySelector('.global-overlay').classList.remove('global-overlay-show')
      setTimeout(() => {
        document.body.classList.remove('tw-overflow-hidden')
      }, 300)
    }
  }

  handleCriticalErrors = (error) => {
    Sentry.captureException(error)
    fetch('/bill_processors/payment_processor_fails', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content
      },
      body: JSON.stringify({pay_system: this.processorNameValue, id: this.billIdValue, error: error.toString()})
    })
      .then((response) => response.json())
      .then((data) => {
        this.processorNameValue = data.new_payment_processor
        this.urlValue = data.bill_url
        this.initPaymentProcessor()
      })
      .catch(({error}) => {
        this.showErrorMessage(error)
        this.showPaymentCardArea = false
      })
  }

  subscribeToChannel() {
    if (this.channel) {
      this.channel.unsubscribe()
    }

    this.channel = cable.subscriptions.create(
      {channel: 'BillPaidChannel', dom_bill_id: this.domBillIdValue, bill_id: this.billIdValue, embill: this.embillValue},
      {received: (e) => this.processEvent(e)}
    )
  }

  initPaymentProcessor() {
    if (!this.processorNameValue) return

    switch (this.processorNameValue) {
      case 'square':
      case 'square_2nd_processor':
        this.paymentProcessor = new SquareProcessor(this.billIdValue, this.processorNameValue)
        break
      case 'stripe_dialog':
        this.paymentProcessor = new StripeProcessor(this.billIdValue)
        break
      case 'testpay':
        this.paymentProcessor = new TestProcessor(this.billIdValue)
        break
      default:
        console.error(`Unknown payment processor: ${this.processorNameValue}`)
        return
    }

    if (!this.paymentProcessor || typeof this.paymentProcessor.init !== 'function') {
      console.error('Payment processor initialization failed')
      return
    }

    this.paymentProcessor.init(this.element, this.handleCriticalErrors)
  }

  processEvent(event) {
    if (event.dom_bill_id !== this.domBillIdValue) return
    this.hideProcessing()

    if (event.status === 'ok') {
      Turbo.cache.clear()
      if (event.request_method === 'post') {
        fetch(event.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content
          },
          body: JSON.stringify({bill_id: this.billIdValue})
        }).then((response) => response.json())
          .then((data) => {
            window.location.href = data.url
          })
      } else {
        window.location.href = event.url
      }
    } else {
      this.payTarget.disabled = false
      this.showErrorMessage(event.error)
      window.tfxEvent('ga_pay_error', {code_error: event.error})
    }
  }

  paymentProcess() {
    if (this.processing) return

    this.showProcessing()
    this.clearErrorMessage()
    this.payTarget.disabled = true

    const process = this.paymentProcessor?.process(
      '',
      'this.cardHolderName', 'this.expirationMonth',
      'this.expirationYear', 'this.cardCvv',
      'this.inputs'
    )

    process?.then((result) => {
      if (result.skipSubmitWaitForWebsocket) {
        this.showLoader = true
      } else {
        fetch(this.urlValue, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content
          },
          body: JSON.stringify({
            bill_id: this.billIdValue,
            processor_name: this.processorNameValue,
            payment_session_token: result.sessionToken,
            g2s_response: JSON.stringify(result.response),
            productId: this.billIdValue,
            invoice_id: this.embillValue,
            userid: this.userIdValue,
            embill: this.embillValue
          })
        })
          .catch((error) => {
            console.error(error)
            this.notifier.notify(error, {type: 'error'})
          })
      }
    }, ({error, ignore}) => {
      if (!ignore) {
        this.showErrorMessage(error)
      }
      this.hideProcessing()
      this.reportError(error)
      this.payTarget.disabled = false
    })
  }

  reportError(errorMessage) {
    fetch('/payments_attemps', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token').item(0).content
      },
      body: JSON.stringify({
        payments_attemp: {
          bill_id: this.billIdValue,
          processor: this.processorNameValue,
          trx_id: null,
          reason: errorMessage,
          payment_params: null
        }
      })
    })
  }

  showErrorMessage(error) {
    this.hideProcessing()
    this.errorMsgTarget.innerText = error
    this.errorMsgTarget.classList.remove('tw-hidden')
  }

  clearErrorMessage() {
    this.errorMsgTarget.innerText = ''
    this.errorMsgTarget.classList.add('tw-hidden')
  }

  hideProcessing() {
    this.processing = false
    this.loadingTarget.classList.add('tw-hidden')
  }

  showProcessing() {
    this.processing = true
    this.loadingTarget.classList.remove('tw-hidden')
  }
}
