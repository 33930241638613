import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileUploadedSuccess', 'fileUploadedFailure', 'offerShow']

  onShowNoTax() {
    window.tfxEvent('instant_quote', {no_tax_return: 1})
  }

  onClickFileUpload() {
    window.tfxEvent('instant_quote', {file_uploaded: 'click'})
  }

  fileUploadedSuccessTargetConnected() {
    window.tfxEvent('instant_quote', {file_uploaded: 'success'})
  }

  fileUploadedFailureTargetConnected() {
    window.tfxEvent('instant_quote', {file_uploaded: 'failure'})
  }

  offerShowTargetConnected() {
    window.tfxEvent('instant_quote', {offer_shown: 1})
  }
}
