export default class TestProcessor {
  constructor() {}

  init() {
    if (document.getElementById('card-element')) {
      document.getElementById('card-element').innerHTML = 'Test processor just push the button to pay'
    }
  }

  process() {
    return Promise.resolve({sessionToken: 'sessionToken', response: 'response'})
  }

  companyName() {
    return 'TEST'
  }

  companyUrl() {
    return 'https://taxesforexpats.com/'
  }
}
