import {Notifier} from '../components/notifier'

import {SQUARE_URL} from 'tfx-common/constants.js'

class PluginsLoader {
  constructor() {
    this.domLoaded = false
    this.loadedScripts = []
  }

  loadScript(url, callback, errorCallback) {
    if (!this.domLoaded) {
      document.addEventListener('turbolinks:load', () => this.loadScript(url, callback))
      return
    }

    if (this.loadedScripts.indexOf(url) !== -1) {
      callback()
      return
    }

    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true

    script.onreadystatechange = () => {
      if (this.readyState === 'complete' || this.readyState === 'loaded') {
        this.loadedScripts.push(url)
        callback()
      }
    }

    script.onload = () => {
      this.loadedScripts.push(url)
      callback()
    }

    script.onerror = function (e) {
      if (errorCallback) {
        errorCallback(e)
      } else {
        const notifier = new Notifier()
        notifier.notify('Error', {text: 'Something went wrong, script load error problem', type: 'error'})
      }

      console.error('Error loading script', e.srcElement.toString(), e)
    }

    script.src = url
    head.appendChild(script)
  }
}

class PluginRegistry {
  constructor() {
    this.known = {}
    this.loaded = {}
    this.loader = new PluginsLoader()
  }

  use(name, callback, errorCallback) {
    if (!callback) {
      callback = function () {
        // do nothing
      }
    }
    if (name in this.loaded) {
      // if loaded just  run callback
      callback()
    } else {
      // if loading put callback in stack
      if (this.known[name].status === 'loading') {
        this.known[name].onLoad.push(callback)
        // else just load
      } else {
        this.known[name].status = 'loading'
        const fn = function () {
          this.known[name].status = 'loaded'
          this.loaded[name] = true

          this.known[name].onLoad.forEach((fn) => fn())
          callback()
        }.bind(this)
        this.loader.loadScript(this.known[name].path, fn, errorCallback)
      }
    }
  }

  register(name, path) {
    this.known[name] = {path, onLoad: []}
  }
}

const Plugin = new PluginRegistry()

Plugin.register('ckeditor', 'https://cdn.ckeditor.com/4.22.1/full/ckeditor.js')
Plugin.register('pdfjs-dist', '/js/pdf.js/pdf.min.js')
Plugin.register('facebook-connect', '//connect.facebook.net/en_US/sdk.js')
Plugin.register('jszip', '//cdn.jsdelivr.net/npm/jszip@3.2.2/dist/jszip.min.js')
Plugin.register('medium-editor', 'https://cdn.jsdelivr.net/npm/medium-editor@latest/dist/js/medium-editor.min.js')
Plugin.register('square-inline-form', SQUARE_URL)
Plugin.register('stripe-inline-form', 'https://js.stripe.com/v3/')
Plugin.register('calendly', 'https://assets.calendly.com/assets/external/widget.js')

export default Plugin
