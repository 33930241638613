import Plugin from 'tfx-common/plugins'

export default class SquareProcessor {
  constructor(billId, processorName) {
    this.card = null
    this.billId = billId
    this.processorName = processorName
    this.billAmount = null
    this.resolvePayment = null
    this.errorPayment = null
    this.inputs = null
    this.cardBrand = null
  }

  init(el, error) {
    Plugin.use('square-inline-form', () => {
      setTimeout(() => { this.initForm(el).catch((e) => error(e)) }, 1000)
    }, error)
  }

  process(cardNumber, cardHolderName, expirationMonth, expirationYear, cardCvv, inputs) {
    this.inputs = inputs
    return new Promise((resolve, error) => {
      this.resolvePayment = resolve
      this.errorPayment = error

      if (this.cardBrand == 'americanExpress') {
        this.errorPayment({error: 'Sorry, we do not accept American Express. Please use another card.'})
        return
      }

      try {
        this.tokenize().then((token) => {
          this.resolvePayment({sessionToken: token, response: {}, skipSubmitWaitForWebsocket: false})
        }).catch((error) => this.errorPayment({error: error.message, ignore: true}))
      } catch (e) {
        this.errorPayment({error: e.message, ignore: false})
      }
    })
  }

  async getSquareParameters(billId, processorName) {
    const response = await fetch(`/bill_processors/square_pays/new.json?id=${billId}&square_processor=${processorName}`)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  async initForm(el) {
    const parameters = await this.getSquareParameters(this.billId, this.processorName)
    this.billAmount = parameters.amountPaymentSystem
    this.billDescription = parameters.billDescription
    this.country = parameters.country

    const payments = window.Square.payments(parameters.applicationId, parameters.locationId)
    this.card = await payments.card()

    const cardChangedCallback = async (cardInputEvent) => {
      this.cardBrand = cardInputEvent.detail.cardBrand
    }
    this.card.addEventListener('cardBrandChanged', cardChangedCallback)
    el.querySelector('#card-element').innerHTML = ''
    await this.card.attach(el.querySelector('#card-element'))
  }

  async tokenize() {
    const tokenResult = await this.card.tokenize()
    if (tokenResult.status === 'OK') {
      return tokenResult.token
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`
      }

      throw new Error(errorMessage)
    }
  }

  findValue(fieldName) {
    const input = Array.from(this.inputs).filter((input) => {
      return input.id === fieldName
    })
    if (input.length > 0) {
      return input[0].value
    } else {
      return null
    }
  }

  companyName() {
    return 'Square'
  }

  companyUrl() {
    return 'https://squareup.com/'
  }
}
