import {Controller} from 'stimulus'
import {Turbo} from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['overlay', 'modal', 'content']

  async open(event) {
    event.preventDefault()
    const csrf = document.getElementsByName('csrf-token').item(0).content
    const url = event.currentTarget.dataset.modalUrl
    const modalRequestMethod = event.currentTarget.dataset.modalRequestMethod || 'get'
    const response = await fetch(url, {method: modalRequestMethod, headers: {'X-CSRF-Token': csrf}})
    this.contentTarget.innerHTML = await response.text()
    this._showModal()
  }

  close() {
    this._hideModal()
    document.body.classList.remove('overflow-hidden', 'tw-overflow-hidden')
    this.contentTarget.innerHTML = ''
    Turbo.visit(window.location.href, {action: 'replace'})
  }

  _showModal() {
    this.modalTarget.classList.remove('tw-opacity-0', 'tw-scale-95')
    this.modalTarget.classList.add('tw-opacity-100', 'tw-scale-100')
    this.overlayTarget.classList.remove('tw-opacity-0')
    this.overlayTarget.classList.add('tw-opacity-100')
    document.querySelector('.global-overlay').classList.add('global-overlay-show')
    document.body.classList.add('overflow-hidden', 'tw-overflow-hidden')
  }

  _hideModal() {
    this.modalTarget.classList.add('tw-opacity-0', 'tw-scale-95')
    this.modalTarget.classList.remove('tw-opacity-100', 'tw-scale-100')
    this.overlayTarget.classList.add('tw-opacity-0')
    this.overlayTarget.classList.remove('tw-opacity-100')
    document.querySelector('.global-overlay').classList.remove('global-overlay-show')
  }
}
