import {Controller} from '@hotwired/stimulus'
import {Turbo} from '@hotwired/turbo-rails'

export default class extends Controller {
  static targets = ['file', 'form']

  upload() {
    if (this.fileTarget.files.length > 0) {
      Turbo.navigator.submitForm(this.formTarget)
    }
  }
}
