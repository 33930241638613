import Plugin from 'tfx-common/plugins'

export default class StripeProcessor {
  constructor(billId) {
    this.stripeEl = null
    this.billId = billId
    this.billAmount = null
    this.resolvePayment = null
    this.errorPayment = null
    this.inputs = null
    this.cardBrand = null
    this.clientSecret = null
    this.card = null
    this.clientName = null
    this.clientEmail = null
  }

  init(el, error) {
    Plugin.use('stripe-inline-form', () => {
      this.initForm(el).catch((e) => error(e))
    }, error)
  }

  process(cardNumber, cardHolderName, expirationMonth, expirationYear, cardCvv, inputs) {
    this.inputs = inputs
    return new Promise((resolve, error) => {
      this.resolvePayment = resolve
      this.errorPayment = error

      if (this.cardBrand == 'amex') {
        this.errorPayment({error: 'Sorry, we do not accept American Express. Please use another card.'})
        return
      }

      this.stripeProcess()
    })
  }

  async getStripeParameters(billId) {
    const response = await fetch(`/bill_processors/stripe_pays/new.json?id=${billId}`)
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return response.json()
  }

  async initForm(el) {
    const parameters = await this.getStripeParameters(this.billId)
    this.billAmount = parameters.amountPaymentSystem
    this.billDescription = parameters.billDescription
    this.country = parameters.country
    this.clientSecret = parameters.client_secret_id
    this.clientName = parameters.clientName
    this.clientEmail = parameters.clientEmail

    /* global Stripe */
    // eslint-disable-next-line
    this.stripeEl = Stripe(parameters.stripe_public_key)
    const elements = this.stripeEl.elements()

    const style = {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
    this.card = elements.create('card', {hidePostalCode: true, style})
    this.card.mount('#card-element')
    this.card.on('change', (event) => {
      this.cardChange(event)
    })
  }

  cardChange(event) {
    this.cardBrand = event.brand
  }

  stripeProcess() {
    const self = this
    this.stripeEl.confirmCardPayment(this.clientSecret, {
      payment_method: {
        card: this.card,
        billing_details: {
          name: this.clientName,
          email: this.clientEmail
        }
      }
    }).then(function (result) {
      if (result.error) {
        self.errorPayment({error: result.error.message})
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          self.resolvePayment({sessionToken: result.id, response: result, skipSubmitWaitForWebsocket: true})
          // this.resolvePayment({sessionToken: nonce, response: 'response'})
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
        }
      }
    })
  }

  companyName() {
    return 'Stripe'
  }

  companyUrl() {
    return 'https://stripe.com/'
  }
}
