import './styles/main.scss'
import Plugin from 'tfx-common/plugins'

document.addEventListener('DOMContentLoaded', () => {
  require('./initializers/rails').default()
  require('./initializers/turbolinks').default()
  require('./initializers/stimulus').default()
  require('./initializers/vue-components').default()
  require('./initializers/lazy-loading').default()
  require('./initializers/legacy-components').default()
  require('./initializers/referrers').default()
  require('./initializers/externals').default()
  const initializeTrustPilot = require('./initializers/trustpilot').default
  initializeTrustPilot()
  Plugin.loader.domLoaded = true
})
